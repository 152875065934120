import React from 'react';
import Highcharts from 'highcharts';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  width: 100% !important;
  margin-top: 30px;
  height: 100%;
  .highcharts-container {
    width: 100% !important;
    > svg {
      width: 100%;
      g{
        text{
          text-transform: capitalize;
          font-size: 14px;
          font-family: 'Rubik'
        }
      }
    }
  }
`;

class VerticalBarChart extends React.Component {
  
  componentDidMount() {
    this.renderGraph(this.props);
  }

  componentDidUpdate(prevProps) {
    if(this.props !== prevProps) {
      this.renderGraph(this.props);
    }
  }

  renderGraph = (props) => {
    const { seriesData, xAxisLabel, yAxisTitle, xAxisTitle, title, fillColor,  id, xAxisVisible, yAxisVisible, fontWeight, wellBeing, cultureSurvey, wellnessGoals} = props;
    const maxLimit = 100;
    const GraphData = [], data = [...seriesData];
    data.map((series, index) => {
      series.stack = index;
      series.dataLabels = {
        color: wellBeing ? '#fff' : series.color,
        style: {
          textShadow: false,
          textOutline: false,
        }
      };
      GraphData.push({
        stack: index,
        data: [],
        showInLegend: false,
        dataLabels: {enabled: false},
        states: {
          hover: {
            enabled: false
          }
        }

      });
      GraphData.map((fillSeries) => {
        if(fillSeries.stack === index) {
          series.data.map((value) => {
            cultureSurvey ? fillSeries.data.push(maxLimit - value.y) : fillSeries.data.push(maxLimit - value);
          });
          fillSeries.color = fillColor ? '#F6F6F8' : 'rgba(12, 153, 193, 0.2)';
        }
      })
    });

    Highcharts.chart( id, {
      chart: {
        type: 'column',
        colorByPoint: true
      },
      title:{
        text: title|| null
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: xAxisLabel,
        crosshair: wellnessGoals ? false : true,
        className: 'xAxis',
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        minorTickLength: 0,
        tickLength: 0,
        visible: xAxisVisible,
        title: {
          text: xAxisTitle || null
        },
        labels: {
          style: {
            color: '#282C37',
            fontFamily: 'Rubik',
            fontSize: '14px',
            fontWeight: fontWeight ? 700 : 500,
            textTranform: 'capitalize',
          }
        }
      },
      yAxis: {
        min: 0,
        max: 100,
        showEmpty: false,
        tickInterval: 20,
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        gridLineColor: 'white',
        visible: yAxisVisible,
        labels: {
          formatter: function() {
            return this.value
          },
          style: {
            color: '#282C37',
            fontFamily: 'Rubik',
            fontSize: '14px',
            fontWeight: 500
          }
        },
        title: {
          text: yAxisTitle || null
        },
        states: {
          inactive: {
            opacity: 1,
            fill: 'transparent'
          }
        }
      },
      tooltip: {
        enabled: true,
        style: {
          fontFamily: wellnessGoals ? 'Rubik-Medium' : 'Rubik',
          fontSize: wellnessGoals ? '15px' : '11px',
          fontWeight: 500,
        },
        formatter: function() {
          return `${this.y}%`;
        }
      },
      legends: {
        enabled: false
      },
      plotOptions: {
        shadow: false,
        column: {
          pointPadding: 0,
          borderWidth: 0,
          states: {
            hover: {
              enabled: false
            },
            inactive: {
              opacity: 1,
            }
          },
          dataLabels: {
            enabled: false,
            crop: false,
            overflow: 'none',
            verticalAlign: 'bottom',
            top: '10%',
            inside: false,
            //y: -1,
            formatter: function() {
              return this.y > 9 ? this.y + '%': ''
            },
            style: {
              fontFamily: wellnessGoals ? 'Rubik-Medium' : 'Rubik',
              fontSize: wellnessGoals ? '15px' : '11px',
              fontWeight: wellnessGoals ? 700 : 500,
            },
            pointPadding: 0.1,
          },
          animation: false
        },
        series: {
          stacking: 'normal'
        }
      },
      labelStyle: {
        fontSize: "8em",
      },
      series: [
        ...GraphData,
        ...data
      ]
    });
  };

  render(){
    const {id, wellnessGoals} =  this.props;
    return (
      <Container id={id} wellnessGoals={wellnessGoals}/>
    )
  }
}

VerticalBarChart.defaultProps = {
  xAxisVisible: true,
  yAxisVisible: true,
  id: "chart"
}

VerticalBarChart.propTypes = {
  xAxisTitle: PropTypes.string,
  yAxisTitle: PropTypes.string,
  xAxisLabel: PropTypes.array,
  yAxisLabel: PropTypes.array,
  seriesData: PropTypes.array,
  yAxisVisible: PropTypes.bool,
  id: PropTypes.string,
  title: PropTypes.string,
  xAxisVisible: PropTypes.bool,
  wellBeing: PropTypes.number,
  wellnessGoals: PropTypes.number
};

export default VerticalBarChart;